var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "scroll-container" },
    [
      _c("CategoryTitle", { attrs: { category: _vm.category } }),
      _vm.invoices.length > 0
        ? _c(
            "div",
            { staticClass: "invoice-list pt-1" },
            [
              _c(
                "v-row",
                { staticClass: "header" },
                [
                  _c("v-col", { attrs: { cols: "2" } }, [
                    _c("strong", [_vm._v("Nº")])
                  ]),
                  _c("v-col", { attrs: { cols: "4", sm: "3" } }, [
                    _c("strong", [_vm._v(_vm._s(_vm.$t("invoice.date")))])
                  ]),
                  _c("v-col", { attrs: { cols: "5", sm: "4" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$t("invoice.documentType")))
                    ])
                  ]),
                  _c("v-col", { attrs: { cols: "1", sm: "3" } })
                ],
                1
              ),
              _vm._l(_vm.invoices, function(invoice) {
                return _c(
                  "v-row",
                  { key: invoice.dokuknoxID },
                  [
                    _c("v-col", { attrs: { cols: "2" } }, [
                      _vm._v(_vm._s(invoice.attribDocumentNumber))
                    ]),
                    _c("v-col", { attrs: { cols: "4", sm: "3" } }, [
                      _vm._v(_vm._s(invoice.attribDocumentData))
                    ]),
                    _c(
                      "v-col",
                      { attrs: { cols: "5", sm: "4" } },
                      [
                        invoice.attribTypeOfDocument
                          ? [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    `dashboard.invoice.type.${invoice.attribTypeOfDocument}`
                                  )
                                )
                              )
                            ]
                          : _vm._e()
                      ],
                      2
                    ),
                    _c(
                      "v-col",
                      {
                        class: _vm.$vuetify.breakpoint.xs
                          ? "d-block px-0"
                          : "d-none",
                        attrs: { cols: "1" }
                      },
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: invoice.dokuknoxDownloadLink,
                              target: "_blank"
                            },
                            on: {
                              click: function($event) {
                                return _vm.downloadDocument(
                                  invoice.attribDocumentNumber
                                )
                              }
                            }
                          },
                          [
                            _c("v-icon", { attrs: { color: "primary" } }, [
                              _vm._v("$download")
                            ])
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "v-col",
                      {
                        class: _vm.$vuetify.breakpoint.xs
                          ? "d-none"
                          : "d-block",
                        attrs: { cols: "5", sm: "3" }
                      },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on, attrs }) {
                                    return [
                                      _c(
                                        "a",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass:
                                                "text-decoration-none",
                                              attrs: {
                                                href:
                                                  invoice.dokuknoxDownloadLink,
                                                target: "_blank"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.downloadDocument(
                                                    invoice.attribDocumentNumber
                                                  )
                                                }
                                              }
                                            },
                                            "a",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("invoice.downloadDocument")
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("invoice.downloadDocumentTip"))
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        : _c(
            "v-card",
            {
              staticClass: "pa-2 text-center w-100",
              attrs: { light: "", outlined: "" }
            },
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(_vm._s(_vm.$t("invoice.noAvailableDocuments")))
              ])
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }